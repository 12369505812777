import './Navbar.css';
import Logo from '../materials/Logo.png';

const Navbar = () => {
  return (
    <div className="navbar">
        <img src={Logo} className="navbar-logo" alt="logo" />
        <ul>
            <li><a href='/'>Home</a></li>
            <li><a href='/SocialMedia'>Social</a></li>
            <li><a href='/Portfolio'>Portfolio</a></li>
            <li><a href='#' className='active'>Contact Me</a></li>
        </ul>
    </div>
  );
}

export default Navbar;

import "./Footer.css";

import React from "react";

const footer = () => {
  return (
    <div className="Footer">
      <h1>BayYazilimci</h1>
      <ul className="Menu">
        <h6>Header</h6>
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#">Services</a>
        </li>
        <li>
          <a href="#">Portfolio</a>
        </li>
        <li>
          <a href="#">Contact Me</a>
        </li>
      </ul>
      <ul className="Links">
        <h6>Social Media</h6>
        <li>
          <a href="https://github.com/BayYazilimci">Github</a>
        </li>
        <li>
          <a href="https://www.instagram.com/bay.yazilimci/">Instagram</a>
        </li>
        <li>
          <a href="https://www.youtube.com/@BayYazilimcicom">YouTube</a>
        </li>
        <li>
          <a href="https://bayyazilimci.com/">WebSite</a>
        </li>
      </ul>
      <ul className="News">
        <h6>News</h6>
        <li>
          <a href="#">Blog <span>(Active)</span></a>
        </li>
        <li>
          <a href="#">Forum <span>(Soon)</span></a>
        </li>
      </ul>
    </div>
  );
};

export default footer;

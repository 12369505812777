const Data = [
  {
    id: 3,
    title: "JavaScript Temelleri",
    img: "https://cdn.gcp.techcareer.net/React_mi_Angular_mi_Daha_Avantajli_1_76366b40d2/React_mi_Angular_mi_Daha_Avantajli_1_76366b40d2.jpg",
    shortdesc: "JavaScript diline başlangıç için temel bilgiler.",
    contentBlocks: [
      {
        type: "content",
        content: "Bu yazıda JavaScript dilinin temellerini ele alacağız.",
      },
      {
        type: "code",
        content: `
function greet(name) {
  return "Merhaba, " + name + "!";
}
console.log(greet("Ali")); // Merhaba, Ali!
      `,
      },
      {
        type: "content",
        content: "Yukarıdaki kod bir karşılama mesajı döndürmektedir.",
      },
      {
        type: "code",
        content: `
let x = 5;
let y = 10;
console.log(x + y); // 15
      `,
      },
      {
        type: "content",
        content:
          "Matematiksel işlemler de oldukça basit bir şekilde yapılabilir.",
      },
    ],
  },
  {
    id: 4,
    title: "Diziler ve Döngüler",
    img: "https://cdn.gcp.techcareer.net/React_mi_Angular_mi_Daha_Avantajli_1_76366b40d2/React_mi_Angular_mi_Daha_Avantajli_1_76366b40d2.jpg",
    shortdesc: "JavaScript'te diziler ve döngüler hakkında temel bilgiler.",
    contentBlocks: [
      {
        type: "content",
        content:
          "JavaScript'te diziler ve döngüler veri yönetiminde önemli bir rol oynar.",
      },
      {
        type: "code",
        content: `
const numbers = [1, 2, 3, 4, 5];
for (let i = 0; i < numbers.length; i++) {
  console.log(numbers[i]);
}
// Çıktı: 1 2 3 4 5
      `,
      },
      {
        type: "content",
        content:
          "Yukarıdaki kod, bir dizinin elemanlarını döngü ile yazdırmaktadır.",
      },
      {
        type: "code",
        content: `
const doubled = numbers.map(num => num * 2);
console.log(doubled); // [2, 4, 6, 8, 10]
      `,
      },
      {
        type: "content",
        content:
          "Ayrıca dizilerde `map` fonksiyonu ile her elemanı ikiyle çarpabiliriz.",
      },
    ],
  },
  {
    id: 5,
    title: "JavaScript'te Nesneler",
    img: "https://cdn.gcp.techcareer.net/React_mi_Angular_mi_Daha_Avantajli_1_76366b40d2/React_mi_Angular_mi_Daha_Avantajli_1_76366b40d2.jpg",
    shortdesc: "JavaScript'te nesneler ile veri yönetimi.",
    contentBlocks: [
      {
        type: "content",
        content:
          "Nesneler, JavaScript'te birden fazla değeri tek bir yapıda saklamamıza olanak tanır.",
      },
      {
        type: "code",
        content: `
    const numbers = [1, 2, 3, 4, 5];
    for (let i = 0; i < numbers.length; i++) {
    console.log(numbers[i]);
      }}  }}  }}  }}  }}
    // Çıktı: 1 2 3 4 5
              `,
      },
      {
        type: "content",
        content:
          "Bu örnekte, `person` nesnesi bir kişinin adını ve yaşını saklar.",
      },
      {
        type: "code",
        content: `
    person.job = "Yazılım Geliştirici";
    console.log(person.job); // Yazılım Geliştirici
      `,
      },
      {
        type: "content",
        content: "Nesneye yeni bir özellik eklemek oldukça kolaydır.Nesneye yeni bir özellik eklemek oldukça kolaydır.Nesneye yeni bir özellik eklemek oldukça kolaydır.Nesneye yeni bir özellik eklemek oldukça kolaydır.Nesneye yeni bir özellik eklemek oldukça kolaydır.",
      },
    ],
  },
];

export default Data;

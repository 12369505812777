import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Data from "./Data";
import Navbar from "../Web/Navbar";
import Footer from "../Web/Footer";
import SyntaxHighlighter from "react-syntax-highlighter";

import "./BlogDetail.css";
import { FaCopy, FaAngleDoubleLeft } from "react-icons/fa";
import Memoji from "../materials/memoji.webp";
import JoyStick from "../materials/joystick.png";
import Sticker from "../materials/sticker.png";

const BlogDetail = () => {
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    const generatePositions = () => {
      const newPositions = [];
      for (let i = 0; i < 15; i++) {
        let validPosition = false;
        let position;

        while (!validPosition) {
          position = {
            top: Math.random() * 100,
            left: Math.random() * 100,
          };

          const collision = newPositions.some(
            (pos) =>
              Math.abs(pos.top - position.top) < 20 &&
              Math.abs(pos.left - position.left) < 20
          );

          if (!collision) {
            validPosition = true;
          }
        }
        newPositions.push(position);
      }
      setPositions(newPositions);
    };

    generatePositions();
  }, []);

  const joystickImages = positions.map((pos, index) => (
    <img
      key={index}
      src={JoyStick}
      alt="Joystick"
      className="Joystick"
      style={{
        top: `${pos.top}%`,
        left: `${pos.left}%`,
        marginLeft: "-200px",
        marginTop: "200px",
        position: "absolute",
        width: "64px",
        opacity: "0.05",
      }}
    />
  ));

  const StickerImages = positions.map((pos, index) => (
    <img
      key={index}
      src={Sticker}
      alt="Joystick"
      className="Joystick"
      style={{
        top: `${pos.top}%`,
        left: `${pos.left}%`,
        marginLeft: "-400px",
        marginTop: "200px",
        position: "absolute",
        width: "256px",
        opacity: "0.05",
      }}
    />
  ));

  const { id } = useParams();
  const blog = Data.find((item) => item.id === parseInt(id));

  // State for scroll progress
  const [scrollTop, setScrollTop] = useState(0);

  // Scroll event handler
  const onScroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    setScrollTop(scrolled);
  };

  // Register scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // Function to highlight certain keywords
  const highlightText = (text) => {
    const keywords = {
      JavaScript: "#d73a49",
      function: "#005cc5",
      "console.log": "#6f42c1",
      const: "#e36209",
      return: "#0366d6",
    };

    let highlightedText = text;
    Object.keys(keywords).forEach((keyword) => {
      const color = keywords[keyword];
      const regex = new RegExp(`\\b${keyword}\\b`, "g");
      highlightedText = highlightedText.replace(
        regex,
        `<span style="color: ${color}; font-weight: bold;">${keyword}</span>`
      );
    });

    return highlightedText;
  };

  // Early return if blog is not found
  if (!blog) {
    return <p>Blog not found</p>;
  }

  return (
    <div>
      {joystickImages}
      {StickerImages}
      <div className="progressMainWrapper">
        <div
          className="progressMainStyle"
          style={{ width: `${scrollTop}%` }}
        ></div>
      </div>
      <Navbar />
      <img src={Memoji} alt="memoji" className="Memoji" />
      <div className="BlogDetail">
        <div className="Content">
          {blog.img && (
            <img src={blog.img} alt={blog.title} className="HeaderImage" />
          )}
          <h1>{blog.title}</h1>
          <br />
          {blog.contentBlocks.map((block, index) => {
            if (block.type === "content") {
              return (
                <div
                  key={index}
                  style={{
                    marginBottom: "15px",
                    marginTop: "15px",
                    marginLeft: "15px",
                    fontSize: "24px",
                    fontWeight: "300",
                    color: "#fff",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: highlightText(block.content),
                  }}
                />
              );
            } else if (block.type === "code") {
              return (
                <div key={index} style={{ position: "relative" }}>
                  <SyntaxHighlighter
                    language="javascript"
                    customStyle={{
                      backgroundColor: "#0a0a0a",
                      borderRadius: "20px",
                      fontSize: "2px",
                      position: "relative",
                    }}
                    codeTagProps={{
                      style: {
                        color: "#00C8FF",
                        fontSize: "30px",
                        fontWeight: "700",
                        fontFamily: "Josefin Sans",
                      },
                    }}
                  >
                    {block.content}
                  </SyntaxHighlighter>
                  <button
                    style={{
                      position: "absolute",
                      top: "30px",
                      right: "30px",
                      padding: "5px 10px",
                      backgroundColor: "#0a0a0a",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      zIndex: "1", // Butonu en üstte gösterir
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(block.content);
                      alert("Kod kopyalandı!");
                    }}
                  >
                    <FaCopy size="20" />{" "}
                  </button>
                </div>
              );
            }
            return null;
          })}
          <a className="BackButton" href="/">
            <FaAngleDoubleLeft size="50" />{" "}
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetail;
